<script setup>
import { ArrowLeft } from '@element-plus/icons-vue'
import { reactive, ref, getCurrentInstance, defineExpose } from 'vue'
import { ElMessage, ElNotification } from 'element-plus'
import dayjs from "dayjs"

const { appContext } = getCurrentInstance()


let tableData = ref()

const pageinfo = reactive({
  currentPage: 1,
  total: 1,
  perPage: 15,
})

const rules = reactive({
  name: [
    {
      required: true,
      message: '请输入收银员姓名',
    }
  ],
  phone: [
    {
      required: true,
      message: '请输入收银员电话',
    }
  ],
  password: [
    {
      required: true,
      message: '请输入收银员密码',
    }
  ]
})

const pageChange = (page) => {
  queryCashier({ page: page })
}

const handlebanck = () => {
  isShowCashier.value = false
}

let editdata = ref({})
let cashierisshow = ref(false)

const handleEdit = (row) => {
  cashierisshow.value = !cashierisshow.value
  editdata.value = row
  editdata.value.store = []
  editdata.value.store_ids = []
  editdata.value.stores.forEach(item => {
    editdata.value.store_ids.push(item.id)
  })
  queryShops(row.merchant_id)
}

const handleDel = (row) => {
  deleteCashiers(row.id)
}

const stores = ref()

const handleSeveEdit = () => {
  let data = {
    id: editdata.value.id,
    merchant_id: editdata.value.merchant_id,
    name: editdata.value.name,
    phone: editdata.value.phone,
    store_ids: editdata.value.store_ids,
    _method: "put"
  }
  if (editdata.value.password) {
    data.password = editdata.value.password
  }
  editCashiers(data.id, data)
}

const queryCashier = (data) => {
  appContext.config.globalProperties.$api.merchants.queryCashiers(data).then(res => {
    if (res.status) {
      tableData.value = res.data.data
      pageinfo.total = res.data.pagination.total
      pageinfo.currentPage = res.data.pagination.currentPage
      pageinfo.perPage = res.data.pagination.perPage
    } else {
      ElMessage.error("数据加载失败,请稍后重试！")
    }
  }).catch(err => {
    console.log(err);
  })
}

const deleteCashiers = (id) => {
  appContext.config.globalProperties.$api.merchants.delCashiers(id).then(res => {
    if (res.status == 200) {
      ElNotification({
        title: '删除成功',
        message: '收银员删除成功',
        type: 'success',
      })
      queryCashier({ merchant_id: sessionStorage.getItem('queryID') })
    } else {
      ElNotification({
        title: `删除失败${res.status}`,
        message: '收银员删除失败',
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 查询门店
const queryShops = (id) => {
  appContext.config.globalProperties.$api.merchants.queryStores({ merchant_id: id }).then(res => {
    if (res.status == 200) {
      stores.value = res.data.data
      stores.value.forEach((item) => {
        editdata.value.store.push(item)
      })
    } else {
      ElMessage.error('查询失败，请稍后重试！')
    }
  }).catch(err => {
    console.log(err);
  })
}

const editCashiers = (id, data) => {
  appContext.config.globalProperties.$api.merchants.editCashiers(id, data).then(res => {
    if (res.status == 200) {
      ElNotification({
        title: '修改成功',
        message: '收银员信息修改成功',
        type: 'success',
      })
      cashierisshow.value = !cashierisshow.value
      queryCashier({ merchant_id: sessionStorage.getItem('queryID') })
    } else {
      ElNotification({
        title: `修改失败${res.status}`,
        message: '收银员修改失败',
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
    ElNotification({
      title: `修改失败${err.response.status}`,
      message: err.response.data.error.fields,
      type: 'error',
    })
  })
}

const isShowCashier = ref(false)


const open = (item) => {
  isShowCashier.value = true
  sessionStorage.setItem("queryID", item.id)
  queryCashier({ merchant_id: item.id })
}

defineExpose({ open })


</script>

<template>

  <el-dialog v-model="isShowCashier" width="80%" :destroy-on-close="false" :show-close=false :fullscreen=true>
    <el-page-header :icon="ArrowLeft" @back="handlebanck">
      <template #content>
        <span> 收银员管理 </span>
      </template>
    </el-page-header>

    <div class="table-container">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="序号" width="80" type="index" align="center">
          <template #default="scope">
            {{ (pageinfo.currentPage -1) * pageinfo.perPage +scope.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="收银员姓名" />
        <el-table-column prop="phone" label="收银员电话" />
        <el-table-column prop="created_at" label="创建时间" width="200px">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div class="select-btn">
              <el-link :underline="false" @click="handleEdit(scope.row)" type="primary">编辑</el-link>
              <el-link :underline="false" @click="handleDel(scope.row)" type="primary">删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" :total=pageinfo.total @current-change="pageChange" :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
    </div>

    <!-- edit  -->
    <el-dialog v-model="cashierisshow" title="编辑收银员" :show-close="false" width="30%">
      <div class="cashier-edit">
        <el-form :modal="editdata" label-width="100px" label-position="right" :rules="rules">
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="editdata.name" clearable style="width: 280px" placeholder="请输入收银员姓名" />
          </el-form-item>
          <el-form-item label="电话：" prop="phone">
            <el-input v-model="editdata.phone" clearable style="width: 280px" placeholder="请输入收银员电话" />
          </el-form-item>
          <el-form-item label="修改密码：">
            <el-input v-model="editdata.password" clearable style="width: 280px" placeholder="请输入收银员密码" />
          </el-form-item>
          <el-form-item label="绑定门店：">
            <el-select multiple placeholder="请选择门店" style="width: 280px" v-model="editdata.store_ids">
              <el-option v-for="store in editdata.store" :key="store.id" :label="store.name" :value="store.id" />
            </el-select>
          </el-form-item>
          <template #empty>
            <el-empty description="空数据" :image-size="200" />
          </template>
        </el-form>
      </div>
      <template #footer>
        <el-button type="primary" @click="cashierisshow = false">取消</el-button>
        <el-button type="primary" @click="handleSeveEdit()">确认</el-button>
      </template>
    </el-dialog>
    <!-- edit end -->
  </el-dialog>

</template>

<style lang="less" scoped>
.table-container {
  margin: 20px 0;

  .select-btn {
    width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
  }
}

.pagination {
  margin: 1.25rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cashier-edit {
  width: 70%;
  margin: 0 auto;
}
</style>